@import "../../styles/global.scss";

.heroMain {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: sizeRem(20px);
  background-color: rgba(var(--color-light-grey));

  .left {
    grid-column: 2/12;
    column-gap: sizeRem(20px);
    background-color: rgba(var(--color-light-grey));

    @include bp-md {
      grid-column: 1/6;
      column-gap: sizeRem(20px);
      grid-template-columns: repeat(12, 1fr);
      display: grid;
    }

    .text-container {
      padding: sizeRem(120px) 0 sizeRem(40px) 0;
      & .buy-hero {
        @include heading-xs;
        margin: sizeRem(40px) 0;
      }
      @include bp-md {
        padding: sizeRem(180px) 0 sizeRem(60px) 0;
        grid-column: 2/12;
        & .buy-hero {
          @include heading-xs;
          margin: sizeRem(80px) 0;
        }
      }
      .download-text {
        @include heading-2xs;
        font-family: "Clarendon";
        line-height: 140%;
      }
      .download-container {
        padding-top: sizeRem(20px);

        & .download-hero {
          &:first-child {
            margin-bottom: sizeRem(30px);
          }
        }
        @include bp-md {
          padding: sizeRem(40px) 0 sizeRem(100px) 0;
        }
      }
    }

    .subText {
      font-family: "Born Ready", sans-serif;
      font-size: sizeRem(32px);
      color: rgba(var(--color-pink));

      @include bp-md {
        font-size: sizeRem(64px);
      }
    }
  }
  .hero-main-right {
    z-index: 0;
    position: sticky;
    top: 0;
    grid-column: 1/13;
    background-color: rgba(var(--color-green));
    max-height: 100vh;

    @include bp-md {
      grid-column: 6/13;
    }
    .img-container {
      z-index: 10;
      position: relative;
      width: 50%;
      margin: 0 auto;
      padding-top: 30px;
      top: 100%;
      transform: translateY(-99.5%);
      img {
        width: 100%;
        height: auto;
        @include bp-md {
          width: 120%;
        }
      }
    }

    .absolute-container {
      z-index: 2;
      position: absolute;
      bottom: 0;
      right: 50%;
      transform: translate(25%, 1%);
      @include bp-md {
        right: 50%;
        transform: translate(25%, 0.5%);
      }

      svg {
        width: 150%;
        height: auto;
        @include bp-md {
          width: 150%;
        }
      }
    }
  }
}
