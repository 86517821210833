@font-face {
  font-family: "Champion lightweight";
  src: url("../../assets/fonts/ChampionHTF-LightweightRegular/Champion-HTF-Lightweight-Regular.otf")
    format("opentype");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Born ready";
  src: url("../../assets/fonts/BornReadyW05Regular/WebFonts/ae9402c994ca14b82833d8d67529c560.ttf")
    format("truetype");
  //   font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clarendon";
  src: url("../../assets/fonts/ClarendonLTStdLight/ClarendonLStdLight.otf")
    format("opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
