@import "../../styles/global.scss";

.hero-deep {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-top: sizeRem(90px);
  column-gap: sizeRem(20px);
  background-color: rgba(var(--color-light-grey));
  @include bp-md {
    grid-template-columns: repeat(7, 1fr);
    padding: sizeRem(180px) sizeRem(20px);
  }
  .text-container {
    grid-column: 2/12;
    @include bp-md {
      grid-column: 2/7;
    }
    .sub-tag-container {
      position: relative;
      .sub-tag {
        @include heading-sm;
        font-family: "Born ready", sans-serif;
        line-height: 120%;
        color: rgba(var(--color-pink));
        position: absolute;
        top: sizeRem(-25px);
        left: 58%;
        rotate: -5deg;
        @include bp-md {
          top: sizeRem(-50px);
        }
      }
    }

    .title {
      padding-bottom: sizeRem(40px);
      @include bp-md {
        padding-bottom: sizeRem(80px);
      }
    }
    .alinia-space {
      grid-column: 1/13;
      padding-bottom: sizeRem(20px);
    }
    .buy-hero-deep {
      @include heading-xs;
      margin: sizeRem(20px) 0;
      @include bp-md {
        margin: sizeRem(80px) 0;
      }
    }
  }
  .download-text {
    @include heading-2xs;
    font-family: "Clarendon";
    line-height: 140%;
  }
  .download-container {
    padding: sizeRem(20px) 0;

    & .download-hero {
      margin-bottom: sizeRem(10px);
      @include bp-md {
        margin-bottom: sizeRem(30px);
      }
      &:last-child {
        margin-bottom: sizeRem(0);
      }
    }
    @include bp-md {
      padding-top: sizeRem(40px);
    }
  }

  .image-container {
    @include bp-md {
      display: none;
    }
    grid-column: 1/13;
    width: 100%;
    aspect-ratio: 5/4;
    background-color: yellow;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: rgba(var(--color-orange));
    .image {
      width: 100% * 0.8;
      height: auto;
    }
    .fullscreen-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
