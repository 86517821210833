@import "../../styles/global.scss";
.hamburger-menu {
  padding: sizeRem(10px);
  display: flex;
  justify-content: space-between;
  align-items: start;

  .logo {
    width: sizeRem(51px);
    height: sizeRem(48px);
  }

  .hamburger {
    position: relative;
    width: sizeRem(48px);
    height: sizeRem(48px);

    .dot {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      width: 10px;
      height: 10px;
      border-radius: 99px;
      background-color: rgba(var(--color-green));
      transition: 1s ease;
      &.active {
        transition: 1s ease;
        transform: scale(200);
      }
    }
  }
}
.listNav {
  position: relative;
  z-index: 10;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: sizeRem(20px);
  padding: sizeRem(100px) sizeRem(40px) sizeRem(10px) sizeRem(40px);
  opacity: 1;
  visibility: visible;
  transition: 1s ease;
  &.none {
    max-height: 0;
    opacity: 0;
    transition: opacity 0.8s ease, visibility 0.8s ease, height 0.8s ease;
    visibility: hidden;
  }
  .container-sec {
    grid-column: 1/12;
    .footer-nav {
      padding-bottom: sizeRem(100px);
      li {
        padding-bottom: sizeRem(20px);
        &:last-child {
          padding-bottom: 0;
        }
        a {
          @include heading-xs;
          font-family: "Clarendon", sans-serif;
          line-height: 100%;
          color: rgba(var(--text-color-dark));
        }
      }
    }
  }
  .container-third {
    grid-column: 1/13;
    display: flex;
    justify-content: space-between;
    padding-bottom: sizeRem(20px);
    flex-direction: column;
    .social-container {
      display: flex;
      flex-direction: column;
      a {
        @include heading-xs;
        font-family: "Champion lightweight", sans-serif;
        line-height: 100%;
        text-transform: uppercase;
        color: rgba(var(--text-color-dark));

        &:first-child {
          padding-bottom: sizeRem(14px);
        }

        &:first-child {
          padding-right: sizeRem(28px);
        }
      }
    }
    .contact-container {
      margin-top: sizeRem(40px);

      a {
        @include heading-xs;
        font-family: "Champion lightweight", sans-serif;
        line-height: 100%;
        text-transform: uppercase;
        color: rgba(var(--text-color-dark));

        &:first-child {
          padding-right: sizeRem(28px);
        }
      }
    }
  }
  .container-fourth {
    grid-column: 1/12;

    .rights {
      @include heading-2xs;
      font-family: "Clarendon", sans-serif;
      color: rgba(var(--text-color-dark));
      line-height: 140%;
      letter-spacing: -0.18px;
    }
  }
}
