@mixin bp-max-xs {
  @media screen and (max-width: calc(375px - 1px)) {
    @content;
  }
}

@mixin bp-sm {
  @media screen and (min-width: 375px) {
    @content;
  }
}

@mixin bp-max-sm {
  @media screen and (max-width: calc(768px - 1px)) {
    @content;
  }
}

@mixin bp-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin bp-max-md {
  @media screen and (max-width: calc(1025px - 1px)) {
    @content;
  }
}

@mixin bp-lg {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin bp-xl {
  @media screen and (min-width: 1921px) {
    @content;
  }
}
