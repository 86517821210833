@import "../../styles/global.scss";

.page-book {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  .left {
    @media screen and (max-width: calc(768px - 1px)) {
      display: none;
    }
    position: fixed;
    width: 100% / 12 * 4;
    height: 100vh;
    overflow: hidden;
    &.orange {
      background-color: rgba(var(--color-orange));
    }
    &.pink {
      background-color: rgba(var(--color-pink));
    }

    .wrapper {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
    .image {
      width: 100% * 0.8;
      height: auto;
    }
    .fullscreen-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & .right {
    @media screen and (max-width: calc(768px - 1px)) {
      grid-column: 1/13;
    }
    grid-column: 5/13;
  }
}
