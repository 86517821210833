@import "../../styles/global.scss";

.stats {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: sizeRem(20px);
  padding: sizeRem(80px) 0 sizeRem(60px) 0;
  background-color: rgba(var(--color-dark-blue));
  @include bp-md {
    padding: sizeRem(155px) 0;
  }

  .tag-container {
    position: relative;
    grid-column: 2/12;
    padding-bottom: sizeRem(50px);
    @include bp-md {
      grid-column: 2/5;
      padding-bottom: sizeRem(70px);
    }
    .tag {
      @include heading-xs;
      font-family: "Champion lightweight", sans-serif;
      line-height: 120%;
      letter-spacing: 0.48px;
      text-transform: uppercase;
      color: rgba(var(--color-white));
    }
    .sub-tag {
      @include heading-sm;
      font-family: "Born Ready", sans-serif;
      line-height: 120%;
      color: rgba(var(--color-light-blue));
      position: absolute;
      top: -20px;
      left: -15px;
      rotate: -5deg;
      @include bp-md {
        top: -40px;
        left: -20px;
      }
    }
  }

  .stats-container {
    grid-column: 2/12;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include bp-md {
      flex-direction: row;
    }
    .stat {
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
      .img-container {
        img {
          width: sizeRem(180px);
          height: sizeRem(180px);
          object-fit: contain;
        }
      }

      .number {
        @include display-md;
        font-family: "Champion lightweight", sans-serif;
        color: rgba(var(--color-white));
        padding-right: sizeRem(24px);
      }
    }
    .stat-text {
      @include heading-xs;
      font-family: "Clarendon", sans-serif;
      line-height: 120%;
      color: rgba(var(--color-white));
      text-align: center;
      padding: sizeRem(16px) 0 sizeRem(60px) 0;
      @include bp-md {
        padding-top: sizeRem(32px);
      }
      .relative {
        position: relative;
        & .d-arrow {
          position: absolute;
          bottom: -30px;
          left: 0px;
          @include bp-md {
            bottom: -50px;
            left: 25px;
          }
          svg {
            width: 60%;
            height: auto;
            @include bp-md {
              width: unset;
            }
          }
        }
        & .circle {
          position: absolute;
          bottom: sizeRem(-5px);
          left: sizeRem(-10px);
          @include bp-md {
            bottom: sizeRem(-10px);
          }
          svg {
            width: 100%;
            height: auto;
            @include bp-md {
            }
          }
        }
        & .underline {
          position: absolute;
          bottom: sizeRem(-12px);
          left: sizeRem(0px);
          @include bp-md {
            bottom: sizeRem(-20px);
          }
          svg {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
