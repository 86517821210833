@import "../../styles/global.scss";
.bio {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: sizeRem(20px);
  padding: sizeRem(80px) 0 sizeRem(40px) 0;
  @include bp-md {
    padding: sizeRem(165px) 0 sizeRem(165px) 0;
  }
  .text-container {
    grid-column: 2/12;
    @include bp-md {
      grid-column: 2/12;
    }
    .sub-tag-container {
      position: relative;
      .sub-tag {
        @include heading-sm;
        font-family: "Born ready", sans-serif;
        line-height: 120%;
        color: rgba(var(--color-pink));
        position: absolute;
        top: sizeRem(-25px);
        left: 58%;
        rotate: -5deg;
        @include bp-md {
          top: sizeRem(-50px);
        }
      }
    }
    & .tag {
      @include heading-xs;
      font-family: "Champion lightweight", sans-serif;
      text-transform: uppercase;
      text-align: center;
      padding-bottom: sizeRem(65px);
      &.pink {
        color: rgba(var(--color-pink));
      }
      &.black {
        color: rgba(var(--text-color-dark));
      }
      @include bp-md {
        padding-bottom: sizeRem(85px);
      }
    }

    .bio-container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      .text-bio {
        grid-column: 1/13;
        padding-bottom: sizeRem(20px);
        // text-align: center;
        @include bp-md {
          grid-column: 3/11;
        }
      }
    }
  }
}
