@import "../../styles/global.scss";
.section-pricing {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: sizeRem(30px) 0;
  column-gap: sizeRem(20px);
  background-image: url(../../Images/png/SvA.png);
  &.purple {
    background-color: rgba(var(--color-dark-blue));
  }
  &.green {
    background-color: rgba(var(--color-green));
  }
  @include bp-md {
    grid-template-columns: repeat(7, 1fr);
    padding: sizeRem(80px) 0;
  }
  .text-container {
    &.white {
      color: rgba(var(--color-white));
    }

    &.black {
      color: rgba(var(--color-text-dark));
    }
    grid-column: 2/12;
    @include bp-md {
      grid-column: 2/7;
    }
    .title {
      padding-bottom: sizeRem(20px);
    }

    .price {
      @include heading-md;
      font-family: "Champion lightweight", sans-serif;
      text-transform: uppercase;
      line-height: 100%;
      letter-spacing: sizeRem(-1.5px);
      padding-bottom: sizeRem(20px);
      span {
        font-family: "born ready", sans-serif;
        line-height: 140%;
      }
    }
    .alinia-spacing {
      padding-bottom: sizeRem(20px);
    }
    .button {
      padding: 10px 0;

      &.button-pricing-white {
        svg {
          width: 38px;
          height: 38px;
          transform: rotate(0deg);
        }
        p {
          color: rgba(var(--color-white));
        }
      }
      &.button-pricing-black {
        svg {
          width: 38px;
          height: 38px;
          transform: rotate(0deg);
        }
        p {
          color: rgba(var(--color-text-dark));
        }
      }
    }
  }
}
