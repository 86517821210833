@import "../../styles/global.scss";
.newsletter {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: sizeRem(20px);
  padding: sizeRem(60px) 0;
  @include bp-md {
    padding: sizeRem(147px) 0;
  }
  .text-container {
    grid-column: 2/12;
    @include bp-md {
      grid-column: 2/6;
    }
    .tag {
      @include heading-xs;
      font-family: "Champion lightweight", sans-serif;
      line-height: 120%;
      text-transform: uppercase;
      padding-bottom: sizeRem(20px);
    }
    .sub-text {
      padding-top: sizeRem(36px);
    }
  }
  .input-form-container {
    grid-column: 2/12;
    align-self: center;
    position: relative;
    padding-top: sizeRem(100px);
    @include bp-md {
      grid-column: 8/12;
      padding-top: sizeRem(50px);
    }
    .input-field-container {
      position: relative;

      .input-field {
        &:focus-visible {
          outline: 1px solid rgba(var(--color-pink));
          border-radius: 10px;
          transition: 0.2s ease;
        }
        &::-webkit-input-placeholder {
          @include heading-2xs;
          font-family: "Clarendon", sans-serif;
          line-height: 140%;
          letter-spacing: -0.16px;
        }
        &::-moz-placeholder {
          @include heading-2xs;
          font-family: "Clarendon", sans-serif;
          line-height: 140%;
          letter-spacing: -0.16px;
        }
        &:-ms-input-placeholder {
          @include heading-2xs;
          font-family: "Clarendon", sans-serif;
          line-height: 140%;
          letter-spacing: -0.16px;
        }
        &:-moz-placeholder {
          @include heading-2xs;
          font-family: "Clarendon", sans-serif;
          line-height: 140%;
          letter-spacing: -0.16px;
        }
        width: 100%;
        position: relative;
        z-index: 1;
        padding: sizeRem(13px);
        border: none;
        border-bottom: 1px solid rgba(var(--color-text-dark));
        transition: 0.5s ease;
      }

      .submit-button {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: sizeRem(0);
        background-color: transparent;
        border: none;
        svg {
          width: sizeRem(32px);
          height: auto;
          circle {
            fill: rgba(var(--color-green));
          }
        }
        @include bp-md {
          bottom: sizeRem(0px);
          svg {
            path {
              animation: ArrowMoveBack 0.3s both;
            }
          }
          &:hover {
            svg {
              circle {
                transition: 0.5s ease;
                fill: rgba(var(--color-text-dark));
              }
              path {
                animation: ArrowMove 0.3s both;
              }
            }
          }
        }
      }
    }

    .input-text-container {
      position: relative;
      z-index: 0;
      .input-text {
        @include heading-sm;
        font-family: "Born ready", sans-serif;
        line-height: 120%;
        color: rgba(var(--color-pink));
        padding-top: sizeRem(15px);
        rotate: -5deg;
      }
    }
  }
}
