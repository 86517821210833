@import "../../styles/global.scss";
.section-fullscreen-img {
  width: 100%;
  height: auto;
  aspect-ratio: 5/4;

  .img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
