@import "../../node_modules/modern-normalize/modern-normalize.css";
@import "./font-family";
@import "./global.scss";

$html-font-size: 16px;

:root {
  --root-size: 16px;

  --color-green: 76, 167, 71;
  --color-light-blue: 147, 178, 242;
  --color-dark-blue: 61, 60, 152;
  --color-pink: 233, 13, 125;
  --color-white: 255, 255, 255;
  --color-light-grey: 241, 241, 241;
  --color-orange: 244 170 30;

  --color-text-dark: 37, 38, 50;
}

* {
  box-sizing: border-box;
}
html {
  font-size: var(--root-size);
}
body {
  overflow-x: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-block-start: 0;
  margin-block-end: 0;
}
li,
ul,
a,
img {
  list-style: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
}
