@import "../../styles/global.scss";
.footer {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: sizeRem(20px);
  background-color: rgba(var(--color-pink));
  padding: sizeRem(15px);
  @include bp-md {
    padding: sizeRem(10px) sizeRem(40px);
  }

  .container-first {
    grid-column: 1/13;
    display: flex;
    justify-content: space-between;
    padding: sizeRem(20px) 0;

    .logo {
      @include bp-md {
        width: 100px;
        height: 100px;
      }

      path {
        fill: rgba(var(--color-white));
      }
    }
    .button {
      .arrow {
        @include bp-md {
          &:hover {
            path {
              animation: ArrowMove 0.4s;
            }
          }
        }
        rotate: -90deg;
        cursor: pointer;
        transition: 0.3s ease;
        circle {
          fill: rgba(var(--color-white));
        }
        path {
          fill: rgba(var(--color-pink));
        }
      }
    }
  }

  .container-third {
    grid-column: 1/13;
    display: flex;
    justify-content: space-between;
    padding: sizeRem(230) 0 sizeRem(20px) 0;
    flex-direction: column;
    @include bp-md {
      flex-direction: row;
    }
    .social-container {
      display: flex;
      flex-direction: column;
      a {
        @include heading-xs;
        font-family: "Champion lightweight", sans-serif;
        line-height: 100%;
        text-transform: uppercase;
        color: rgba(var(--color-white));
        @include bp-max-sm {
          &:first-child {
            padding-bottom: sizeRem(14px);
          }
        }
        @include bp-md {
          &:hover {
            animation: ShineText 0.1s;
          }
        }

        &:first-child {
          padding-right: sizeRem(28px);
        }
      }

      @include bp-md {
        flex-direction: row;
      }
    }
    .contact-container {
      @include bp-max-sm {
        margin-top: sizeRem(40px);
      }

      a {
        @include bp-md {
          &:hover {
            animation: ShineText 0.1s;
          }
        }
        @include heading-xs;
        font-family: "Champion lightweight", sans-serif;
        line-height: 100%;
        text-transform: uppercase;
        color: rgba(var(--color-white));

        &:first-child {
          padding-right: sizeRem(28px);
        }
      }
    }
  }
  .container-fourth {
    grid-column: 1/12;

    .rights {
      @include heading-2xs;
      font-family: "Clarendon", sans-serif;
      color: rgba(var(--color-white));
      line-height: 140%;
      letter-spacing: -0.18px;
    }
  }
}
