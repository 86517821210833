@import "../../styles/global.scss";

.services {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
  &.green {
    background-color: rgba(var(--color-green));
  }
  &.dark-blue {
    background-color: rgba(var(--color-dark-blue));
    color: white;
  }
  @include bp-max-sm {
    padding: sizeRem(60px) 0 sizeRem(20px) 0;
  }
  .tag {
    @include bp-max-sm {
      grid-column: 2/12;
    }
    grid-column: 2/6;
    @include heading-xs;
    font-family: "Champion lightweight";
    line-height: 120%;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    &.dark-blue {
      color: rgba(var(--color-white));
    }
    &.green {
      color: rgba(var(--color-text-dark));
    }
    @include bp-md {
      padding: sizeRem(150px) 0 sizeRem(40px) 0;
    }
  }
  .img-container {
    max-height: 99vh;
    @include bp-max-sm {
      display: none;
    }
    grid-column: 1/5;
    &.hero {
      grid-column: 2/5;
      margin-bottom: sizeRem(40px);
    }
    img {
      aspect-ratio: 16/27;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text-container {
    grid-column: 2/12;

    @include bp-md {
      grid-column: 6/13;
    }
    .list {
      padding: sizeRem(0px) 0 sizeRem(60px) 0;
      @include bp-md {
        padding: sizeRem(120px) 0;
        &.no-padding {
          padding: 0 0 sizeRem(120px) 0;
        }
      }

      .list-item {
        .service-button {
          @include heading-lg;
          font-family: "Champion lightweight", sans-serif;
          text-transform: uppercase;
          color: rgba(var(--color-text-dark));
          line-height: 100%;
          display: flex;
          align-items: center;
          margin: sizeRem(20px) 0;
          .arrow {
            width: 40px;
            height: 40px;
            margin-right: 20px;
            circle {
              fill: rgba(var(--color-white));
            }
          }
          @include bp-md {
            opacity: 0.3;
            .arrow {
              display: none;
            }
            &.active {
              opacity: 1;
              .arrow {
                width: unset;
                height: unset;
                margin-right: 20px;
                display: block;
                circle {
                  fill: rgba(var(--color-white));
                }
                path {
                  animation: ArrowMove 0.3s both;
                }
              }
            }
          }
          &.white {
            p {
              color: white !important;
              padding: 0;
            }
          }
        }
      }
    }
    .sub-text {
      padding: 0 0 sizeRem(40px) sizeRem(0);
      @include bp-md {
        padding-right: sizeRem(200px);
      }
    }
  }
}
