@import "../../styles/global.scss";
.section-testemonies {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding-top: sizeRem(90px);
  column-gap: sizeRem(20px);
  background-color: rgba(var(--color-light-grey));
  @include bp-md {
    grid-template-columns: repeat(7, 1fr);
    padding: sizeRem(180px) sizeRem(20px);
  }
  .text-container {
    grid-column: 2/12;
    @include bp-md {
      grid-column: 2/7;
    }
    .subtitle {
      @include heading-xs;
      font-family: "Born ready", sans-serif;
      padding: 0 0 sizeRem(20px) 0;
      color: rgba(var(--color-pink));
      @include bp-md {
        padding: 0 0 sizeRem(20px) 0;
      }
    }
    .title {
      padding-bottom: sizeRem(80px);
    }
    .card {
      padding: sizeRem(40px);
      width: 550px;
      height: auto;
      background-color: yellow;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &.purple {
        background-color: rgba(var(--color-dark-blue));
        color: rgba(var(--color-white));
      }
      &.pink {
        background-color: rgba(var(--color-pink));
        color: rgba(var(--color-white));
      }
      &.green {
        background-color: rgba(var(--color-green));
        color: rgba(var(--color-text-dark));
      }
      .card-place {
        width: 100%;
        height: auto;
        padding-bottom: sizeRem(30px);
        font-family: "Born ready", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;
      }
      .card-text {
        padding-bottom: sizeRem(30px);
        font-family: "Champion lightweight", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        text-transform: uppercase;
      }
      .card-name {
        list-style: circle inside;
        align-self: end;
        font-family: "Champion lightweight", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        text-transform: uppercase;
      }
    }
    .button-container {
      margin: sizeRem(40px) 0;
      display: flex;
      flex-direction: row;
      justify-content: end;
      .btn-left {
        background-color: transparent;
        border: none;
        margin-right: sizeRem(20px);
        .btn {
          transform: rotate(180deg);
          path {
            fill: rgba(var(--color-white)) !important;
          }
          circle {
            fill: rgba(var(--color-text-dark));
          }
          &:hover {
            path {
              animation: ArrowMove 0.3s;
            }
          }
        }
      }
      .btn-right {
        background-color: transparent;
        border: none;
        .btn {
          path {
            fill: rgba(var(--color-white)) !important;
          }
          circle {
            fill: rgba(var(--color-text-dark));
          }
          &:hover {
            path {
              animation: ArrowMove 0.3s;
            }
          }
        }
      }
    }
  }
}
