@import "../../styles/global.scss";
.textblok-deepdive {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: sizeRem(30px) 0;
  column-gap: sizeRem(20px);
  @include bp-md {
    grid-template-columns: repeat(7, 1fr);
    padding: sizeRem(80px) 0;
  }
  .text-container {
    grid-column: 2/12;
    @include bp-md {
      grid-column: 2/7;
    }
    .title {
      padding-bottom: sizeRem(30px);
      @include bp-md {
        padding-bottom: sizeRem(60px);
      }
    }
    .subtitle {
      @include heading-xs;
      font-family: "Champion lightweight", sans-serif;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0 0 sizeRem(20px) 0;
    }
    .alinia-spacing {
      padding-bottom: sizeRem(20px);
      &:last-child {
        padding-bottom: 0;
      }
    }
    .bulletlist-container {
      padding-bottom: sizeRem(30px);
      &:last-child {
        padding-bottom: 0;
      }
      .list {
        .list-item {
          list-style: circle inside;
          @include heading-2xs;
          font-family: "Clarendon", sans-serif;
          line-height: 140%;
        }
      }
    }
  }
}
