@import "../../styles/global.scss";
.button {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  text-align: unset;
  .text {
    font-family: "Champion lightweight", sans-serif;
    text-transform: uppercase;
    color: rgba(var(--color-text-dark));
    line-height: 100%;
    letter-spacing: 0.28px;
    padding-left: sizeRem(20px);
  }

  .icon- {
    &arrow {
      width: 48px;
      height: auto;
      circle {
        transition: 0.5s ease;
      }
      path {
        animation: ArrowMoveBack 0.3s both;
      }
    }
    &download {
      width: 25px;
      height: auto;
    }
    &arrowMenu {
      width: 40px;
      height: 40px;

      circle {
        fill: rgba(var(--color-white));
      }
      path {
        animation: ArrowMove 0.3s both;
      }
    }
    &arrowFooter {
      width: 48px;
      height: 48px;
      transform: rotate(-90deg);
      circle {
        fill: rgba(var(--color-white));
      }
      path {
        fill: rgba(var(--color-pink)) !important;
      }
    }
  }

  @include bp-md {
    cursor: pointer;
    .icon- {
      &arrow {
        width: sizeRem(64px);
      }
      &arrowMenu {
        width: 55px;
        height: 55px;
        display: none;
        transition: 0.5s ease;
      }
      &arrowFooter {
        width: 58px;
        height: 58px;
        path {
          animation: ArrowMoveBack 0.3s both;
        }
      }
    }
    .text {
      &.menu {
        opacity: 0.5;
        transition: 0.5s ease;
      }
    }
    &:hover {
      .text {
        animation: ShineText 0.1s;
        &.menu {
          opacity: 1;
        }
      }
      .icon- {
        &arrow {
          circle {
            transition: 0.5s ease;
            fill: rgba(var(--color-text-dark));
          }
          path {
            animation: ArrowMove 0.3s both;
          }
        }
        &download {
          path {
            &:first-child {
              animation: ArrowDownload 0.3s both;
            }
          }
        }
        &arrowMenu {
          display: block;
          margin-right: sizeRem(30px);
        }
        &arrowFooter {
          path {
            animation: ArrowMove 0.3s both;
          }
        }
      }
    }
  }
}
