@import "../../styles/global.scss";

.marked-text {
  font-family: "Champion lightweight", sans-serif;
  text-transform: uppercase;
  line-height: 100%;
  letter-spacing: sizeRem(-1.5px);
  overflow-wrap: break-word;

  .circle-text {
    position: relative;
    white-space: nowrap;
    & .circle {
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      svg {
        width: 100%;
        height: 100%;
      }
      &.long {
        svg {
          path {
            stroke: rgba(var(--color-pink));
          }
        }
      }
    }
  }
  .underline-text {
    position: relative;
    white-space: nowrap;
    & .underline {
      width: 100%;
      height: 50%;
      position: absolute;
      bottom: 0;
      left: 0;
      svg {
        path {
          stroke: rgba(var(--color-pink));
        }
        width: 100%;
        height: 100%;
      }
    }
  }
  &.center {
    text-align: center;
  }
  &.big {
    @include display-md;
    padding-bottom: sizeRem(40px);
    @include bp-md {
      padding-bottom: sizeRem(70px);
    }
    & .handwritten {
      @include display-md;
      font-family: "Born Ready", sans-serif !important;
      font-size: sizeRem(32px);
      color: rgba(var(--color-pink));
      text-transform: none;
    }
  }
  &.small {
    @include heading-md;
    padding-bottom: sizeRem(0px);
    & .handwritten {
      @include heading-md;
      font-family: "Born Ready", sans-serif !important;
      font-size: sizeRem(32px);
      color: rgba(var(--color-pink));
      text-transform: none;
    }
  }
}
