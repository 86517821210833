@import "../../styles/global.scss";

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  .navMain {
    padding: sizeRem(40px);
    display: flex;
    justify-content: space-between;
    align-items: start;
    .logo {
      svg {
        width: 50%;
        height: auto;
        @include bp-md {
          width: unset;
        }
      }
    }
    .list {
      float: right;
      display: flex;
      gap: sizeRem(40px);
      .position {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "Champion lightweight";
        font-size: sizeRem(10px);
        text-decoration: none;
        color: rgba(var(--color-text-dark));
        @include bp-md {
          font-size: sizeRem(16px);
          &:hover {
            animation: ShineText 0.1s;
          }
        }
        .dot {
          margin-top: sizeRem(8px);
          width: 5px;
          height: 5px;
          border-radius: 99px;
          background-color: rgba(var(--color-green));
          text-align: center;
          transition: 0.3s ease;
          &.inActive {
            opacity: 0;
          }
          &.active {
            opacity: 1;
          }
        }
      }
    }
  }
}
