@keyframes ArrowMove {
  0% {
    transform: translateX(0px);
  }
  33% {
    transform: translateX(20px);
    opacity: 0;
  }
  66% {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    fill: rgba(var(--color-green));
    opacity: 1;
  }
}
@keyframes ArrowMoveBack {
  0% {
    transform: translateX(0px);
  }
  33% {
    transform: translateX(-20px);
    opacity: 0;
  }
  66% {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    fill: rgba(var(--color-text-dark));
    opacity: 1;
  }
}

@keyframes ShineText {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}
@keyframes ArrowDownload {
  from {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-2px);
  }
  75% {
    transform: translateY(5px);
  }
  to {
    transform: translateY(0px);
  }
}
@keyframes HamburgerBgOpen {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1000);
  }
}

@keyframes HamburgerBgClose {
  from {
    transform: scale(1000);
  }

  to {
    transform: scale(1);
  }
}
