/* 
--- GENERAL ----
For the proportional type scaling:
https://type-scale.com/ 

For the clamp() utility calculation: 
https://codesandbox.io/s/clamp-linear-intepolation-based-on-viewport-width-builder-xgkft?from-embed

/*
---- HEADINGS
---- RATIO 1.125
---- font-size mobile | desktop  ---- 
        lg:   25px    | 100px  (H1)
        md:   20px    | 80px  (H2)
        sm:   14px    | 32px  (H3)
        xs:   12px    | 24px  (H4)
        2xs:  10px    | 18px  (H5)
----     
*/

@mixin heading-lg {
  @include bp-max-sm {
    font-size: sizeRem(25px);
  }

  @include bp-md {
    font-size: sizeRem(60px);
  }
  @include bp-xl {
    font-size: sizeRem(80px);
  }
}

@mixin heading-md {
  @include bp-max-sm {
    font-size: sizeRem(40px);
  }

  @include bp-md {
    font-size: sizeRem(80px);
  }
}

@mixin heading-sm {
  @include bp-max-sm {
    font-size: sizeRem(14px);
  }
  @include bp-md {
    font-size: sizeRem(32px);
  }
}
@mixin heading-xs {
  @include bp-max-sm {
    font-size: sizeRem(14px);
  }
  @include bp-md {
    font-size: sizeRem(24px);
  }
}
@mixin heading-2xs {
  @include bp-max-sm {
    font-size: sizeRem(10px);
  }
  @include bp-md {
    font-size: sizeRem(18px);
  }
}

//   /*
// ----      DISPLAY
// ----      font-size mobile | desktop  ----
//         Display-lg:  225px  | 200px
//         Display-md:  225px  | 150px
//   ----
//   */

@mixin display-lg {
  @include bp-max-sm {
    font-size: sizeRem(60px);
  }
  @include bp-md {
    font-size: sizeRem(200px);
  }
}

@mixin display-md {
  @include bp-max-sm {
    font-size: sizeRem(45px);
  }
  @include bp-md {
    font-size: sizeRem(100px);
  }
  @include bp-xl {
    font-size: sizeRem(150px);
  }
}
